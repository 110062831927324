import { DateTime } from 'luxon'

export type DateTimeZone = keyof typeof timeZones
export type DateFormat = keyof typeof formats

const timeZones = {
  UTC: 'Europe/London',
  EST: 'America/New_York',
} as const

const formats = {
  shortDate: 'MM/dd/yyyy', // 03/17/2023
  shortDateTime: 'MM/dd/yyyy h:mm a', // 03/17/2023 2:34 AM
  shortDateNumeric: 'M/d/yyyy', // 3/17/2023
  longDate: 'MMMM d, yyyy', // March 17, 2023
  longDateAbbr: 'MMM d, yyyy', // Mar 17, 2023
  monthYear: 'MMMM yyyy', // March 2023
  monthYearAbbr: 'MMM yyyy', // Mar 2023
  monthYearNumeric: 'MM/yyyy', // 11/2023
  monthYearShortNumeric: 'MM/yy', // 11/23
  monthDayAbbr: 'MMM d', // Mar 17
  monthAbbr: 'MMM', // Mar
  longDateTime: 'MMMM d, yyyy h:mm a', // March 17, 2023 2:34 AM
  longDateTimeAbbr: 'MMM d, yyyy h:mm a', // Mar 17, 2023 2:34 AM
  longDateTimeAbbrSec: 'MMM d, yyyy h:mm:ss a', // Mar 17, 2023 2:34:56 AM
  longDateTimeFull: 'EEEE, MMMM d, yyyy h:mm a', // Friday, March 17, 2023 2:34 AM
  longDateTimeFullAbbr: 'EEE, MMM d, yyyy h:mm a', // Fri, Mar 17, 2023 2:34 AM
  timeShort: 'h:mm a', // 2:34 AM
} as const

export const formatDate = (
  value: Date | string | null | undefined,
  formatType: DateFormat,
  timeZone: DateTimeZone | null = 'EST',
  isValueUTC = false,
): string => {
  if (!value) {
    return ''
  }

  const format = formats[formatType]

  const options = { zone: isValueUTC ? 'utc' : 'local' }

  let valueDate: DateTime

  if (typeof value === 'string') {
    const dateFromISO = DateTime.fromISO(value, options)
    const parsedDate = dateFromISO.isValid ? dateFromISO : DateTime.fromSQL(value, options)

    if (!parsedDate.isValid) {
      return value
    }

    valueDate = parsedDate
  }
  else {
    valueDate = DateTime.fromJSDate(value, options)
  }

  const dateWithZone = timeZone ? valueDate.setZone(timeZones[timeZone]) : valueDate

  return dateWithZone.toFormat(format)
}
