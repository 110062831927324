export const withDisposableAnchor = <R>(callback: (a: HTMLAnchorElement) => R) => {
  const a = document.createElement('a')
  a.style.display = 'none'

  a.addEventListener('click', (event) => {
    event.stopPropagation()
  })

  try {
    return callback(a)
  }
  finally {
    a.remove()
  }
}
